<template>
  <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
    <template v-slot:title>Base Price Profile</template>
    <template v-slot:content>
      <bs-error-message :errors="errorMessages"/>

      <div class="mb-3">
        <label for="timeRange" class="form-label">Profil</label>
        <bs-plain-text v-model="model.profilNama"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Quantity</label>
        <bs-plain-text v-model="model.quantity"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Currency/Unit</label>
        <bs-plain-text :model-value="`${model.currencyNama}/${model.unitNama}`"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Status</label>
        <bs-plain-text :model-value="`${model.statusNama}`"/>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Remark</label>
        <bs-plain-text v-model="model.remarks"/>
      </div>


      <div class="mb-3">
        <label for="code" class="form-label">Valid From</label>
        <div class="mb-3">
          <view-date v-model="model.validityStart"/>
        </div>
      </div>

      <div class="mb-3">
        <label for="code" class="form-label">Valid End</label>
        <div class="mb-3">
          <view-date v-model="model.validityEnd"/>
        </div>
      </div>

    </template>
    <template v-slot:footer>
      <button class="btn btn-sm btn-primary" @click="editData"><i class="bi bi-pencil-square"></i>
        Edit
      </button>
      <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
    </template>
  </bs-popup>
</template>

<script>
import BasePriceService from "@/services/base-price-service";
import ViewDate from "@/components/ViewDate.vue";
// import SecurityService from "@/services/security-service.js";

export default {
  components: {ViewDate},
  data: function () {
    return {
      id: null,
      model: BasePriceService.defaultForEdit(),
      errorMessages: null
    }
  },
  mounted() {
    let id = this.$route.params.id;
    this.id = id;
    this.loadData();
  },
  methods: {
    async loadData() {
      let response = await BasePriceService.getObject(this.id);
      this.model = response.data;
      if (response.status) {
        this.errorMessages = null;
      } else {
        this.errorMessages = response.errorMessages;
      }
    },
    onPopupClosed() {
      this.$router.push('/base-price');
    },
    close() {
      this.$refs.form.closePopup();
    },
    editData() {
      this.$refs.form.closePopup();
      this.$router.push(`/base-price/edit/${this.id}`);
    }
  }
}
</script>